<template>
<!--   <div class="top-block" v-if="showBanner">
    <div class="inner">
      <div class="close-background"></div>
      <img class="banner" src="/assets/images/mildeal.jpg">
      <img class="banner_mobile" src="/assets/images/mildeal_mobile.jpg">
      <div class="close" @click="showBanner = false">
        <img alt="close button" src="/assets/close.svg">
      </div>
    </div>
  </div> -->
  <div class="menu-block">
    <div id="menu" @click.prevent="openMenu">
      <img alt="menu button" src="/assets/menu.svg" width="30" height="30">
    </div>
  </div>
  <SideMenu/>
  <Main/>
  <Galleries/>
  <Architecture/>
  <YoullLikeIt/>
  <Cabins/>
  <AboutProject/>
  <Map/>
  <SocialInfrostructure/>
  <YandexMappp/>
  <ContactForm/>
  <Documents/>
  <Footer/>
  

  <!-- <div class="phone-outer">
    <a id="menu-phone-top" href="tel:+79787267662">
      <div class="rounded-corners">
        <span>+ 7 978 726 76 62</span>
        <img src="/assets/icons/call.svg" alt="">
      </div>
    </a>
  </div> -->
  

  <div class="phone-outer">
    <a id="menu-phone" href="tel:+79781233377">
      <div class="rounded-corners">
        <span>+ 7 978 123 33 77</span>
        <img src="/assets/icons/call.svg" alt="">
      </div>
    </a>
  </div>

</template>

<script>
  import Main from './components/Main.vue'
  import Architecture from './components/Architecture.vue'
  import SideMenu from './components/SideMenu.vue'
  import Cabins from './components/Cabins.vue'
  import AboutProject from './components/AboutProject.vue'
  import Map from './components/Map.vue'
  import YoullLikeIt from './components/YoullLikeIt.vue'
  import Galleries from './components/Galleries.vue'
  import SocialInfrostructure from './components/SocialInfrostructure.vue'
  import ContactForm from './components/ContactForm.vue'
  import YandexMappp from './components/YandexMappp.vue'
  import Documents from './components/Documents.vue'
  import Footer from './components/Footer.vue'

  export default {
    name: 'App',
    components: {
      SideMenu,
      Main,
      Galleries,
      Architecture,
      YoullLikeIt,
      Cabins,
      AboutProject,
      Map,
      SocialInfrostructure,
      YandexMappp,
      ContactForm,
      Documents,
      Footer,
    },
    data () {
      return {
        showBanner: true,
      }
    },
    mounted () {
      window.addEventListener('scroll', this.phoneScroll);
      window.addEventListener('mousemove', this.mouseMoveHandler)
    },
    unmounted () {
      window.removeEventListener('scroll', this.phoneScroll);
    },
    methods: {
      openMenu: function() {
        document.getElementById('side-menu-overlay').classList.add('open')
        document.getElementById('side-menu').classList.add('open')
        document.getElementById('menu').classList.add('isActive')
      },
      closeMenu: function() {
        document.getElementById('side-menu-overlay').classList.close('open')
        document.getElementById('side-menu').classList.close('open')
        document.getElementById('menu').classList.close('isActive')
        document.getElementById('side-menu-overlay').classList.remove('mouseOnLeft')
        document.getElementById('side-menu').classList.remove('mouseOnLeft')
      },
      phoneScroll: function() {
        let currentScroll = document.documentElement.scrollTop;
        if (currentScroll > window.innerHeight) {
          if (document.getElementById('menu-phone')) document.getElementById('menu-phone').classList.add('show')
          if (document.getElementById('menu-phone-top')) document.getElementById('menu-phone-top').classList.add('show')
        } else {
          if (document.getElementById('menu-phone')) document.getElementById('menu-phone').classList.remove('show')
          if (document.getElementById('menu-phone-top')) document.getElementById('menu-phone-top').classList.remove('show')
        }
      },
      mouseMoveHandler(e) {
          if (e.pageX < 20) {
            document.getElementById('side-menu-overlay').classList.add('mouseOnLeft')
            document.getElementById('side-menu').classList.add('mouseOnLeft')
          } else if (e.pageX > 200) {
            document.getElementById('side-menu-overlay').classList.remove('mouseOnLeft')
            document.getElementById('side-menu').classList.remove('mouseOnLeft')
          }
      }

    },
  }
</script>

<style lang="scss">
  #app {
    font-family: Montserrat, Helvetica, Arial, sans-serif;
  }

  body, html {
    padding: 0;
    margin: 0;
    max-width: 100%;
    overflow-x: hidden;
  }
  .top-block {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    max-width: 100%;
    
    .inner {
      display: inline-block;
      position: relative;
      margin: auto;
      max-width: 100%;

      .banner, .banner_mobile {
        z-index: 2;
        max-width: 100%;
      }

      .banner_mobile {
        display: none;
      }

      .close-background {
        position: absolute;
        bottom: -10px;
        right: 0px;
        z-index: 1;
        background: #0e2432;
        width: 30px;
        height: 30px;
        border-radius: 40px;
      }

      .close {
        position: absolute;
        bottom: -10px;
        right: 0px;
        padding: 5px;
        display: inline-block;
        height: 20px;
        width: 20px;
        z-index: 3;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .banner {
      display: none !important;
    }

    .banner_mobile {
      display: block !important;
    }
  }

  .menu-block {
    position: fixed;
    top: 0px;
    right: 0px;
    display: flex;
    justify-items: center;
    z-index: 10;

    #menu {
      display: flex;
      padding: 18px;
    }

    #menu.isActive {
      background: #d2b281;  // green #248a3d;
    }
  }

  #menu-phone-top  {
    color: white;
    align-self: center;
    text-decoration: none;
    display: none;
    position: fixed;
    bottom: 90px;
    right: 30px;

    .rounded-corners {
      display: flex; 
      align-items: center;
      background: #d2b281;
      border-radius: 50px;
      overflow: hidden;

      &:hover {
        span {
          margin-right: 0;
          transition: margin 200ms;
        }
      }

      span {
        color: black;
        margin: 0 20px 0 15px;
        font-weight: bold;
        margin-right: -154px;
      }

      img {
        display: block; 
        height: 30px;
        width: 30px;
        background: #d2b281;
        padding: 10px;
      }
    }
  }

  #menu-phone  {
    color: white;
    align-self: center;
    text-decoration: none;
    display: none;
    position: fixed;
    bottom: 30px;
    right: 30px;

    .rounded-corners {
      display: flex; 
      align-items: center;
      background: #d2b281;
      border-radius: 50px;
      overflow: hidden;

      &:hover {
        span {
          margin-right: 0;
          transition: margin 200ms;
        }
      }

      span {
        color: black;
        margin: 0 20px 0 15px;
        font-weight: bold;
        margin-right: -149px;
      }

      img {
        display: block; 
        height: 30px;
        width: 30px;
        background: #d2b281;
        padding: 10px;
      }
    }
  }

  #menu-phone.show {
    display: inline-block;
  }

  #menu-phone-top.show {
    display: inline-block;
  }

  @media only screen and (max-width: 600px) {
    #menu-phone-top {
      display: none !important;
    }
  }

  @font-face{
    font-family: "Montserrat";
    src: url("./../public/assets/Montserrat/Montserrat-Regular.ttf") format("truetype");
    font-style: normal; 
    font-weight: normal; 
  }

  @font-face{
    font-family: "Montserrat";
    src: url("./../public/assets/Montserrat/Montserrat-Medium.ttf") format("truetype");
    font-style: normal; 
    font-weight: 500; 
  }

  @font-face{
    font-family: "Montserrat";
    src: url("./../public/assets/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
    font-style: normal; 
    font-weight: 600; 
  }

  * {
    font-family: Montserrat, Arial, sans-serif;
  }

</style>
